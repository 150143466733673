<script>

export default {
    model:{
        prop: 'value',
        event: 'change',
    },
    props: {
        value: { type: Object, required:true },
        readonly: { type: Boolean, default: true}
    },
    data(){
        return {
            buffer: this.value,
        }
    },
    methods:{
        emit_change(){
            this.$emit('change', this.buffer);
        }
    }
}
</script>

<template>
    <div v-if="readonly" class="header-cartouche">
        <h1>{{ buffer.nom }}</h1>
        <h2>{{ periode }}</h2>
        <h2>{{ buffer.location }}</h2>
    </div>
    <div v-else class="header-cartouche">
    </div>
</template>

<style scoped>
    .header-cartouche{
        display: flex;
        justify-content: center;
    }
</style>